<template>
    <div class="container">
        <div class="row">
            <md-card class="startnow">
                <md-card-media-cover md-text-scrim>
                    <md-card-media md-ratio="16:9">
                        <img alt="" src="../assets/admin_start/startnow.jpg">
                    </md-card-media>
                    <md-card-area>
                        <md-card-header>
                            <div class="md-title">
                                <span style="float: right; width: 50%; color: black;">
                                    Hallo lieber Admin!
                                </span>
                            </div>
                            <div class="md-content">
                                <span style="float: right; width: 50%; color: black;">
                                    Oben kannst du dich anmelden und alles über den aktuellen Betrieb erfahren.
                                </span>
                            </div>
                        </md-card-header>
                    </md-card-area>
                </md-card-media-cover>
            </md-card>
        </div>
    </div>
</template>

<style lang="scss" scoped>
div.container {
    text-align: center;
}

.md-card.startnow {
    width: 980px;
    max-width: 90%;
    margin: 10px;
    display: inline-block;
    vertical-align: top;
}

div.hello {
    padding-bottom: 10px;
}

.md-title {
    text-align: left;
}

.md-content {
    text-align: left;
}

div.row {
    width: 100%;
    max-width: 100%;
    align-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>

<script>
export default {
    name: 'Admin_Start',
};
</script>
